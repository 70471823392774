import { useEffect, useState } from "react";
import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Services from "./components/Services";
import Preloader from "./components/Preloader";
import SmoothScroll from "./components/SmoothScroll";
import { animate, AnimatePresence, motion } from "framer-motion";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }
    // window.addEventListener("load", handleLoad);
    // return () => window.removeEventListener("load", handleLoad);
  }, []);
  return (
    <>
      {isLoading ? (
        <motion.div
          variants={{
            open: { opacity: 1 },
            close: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
          animate={isLoading ? "open" : "close"}
          transition={{ duration: 1 }}
        >
          <Preloader />
        </motion.div>
      ) : (
        <AnimatePresence>
          <motion.div
            variants={{
              open: { opacity: 1 },
              close: { opacity: 0 },
            }}
            initial={{ opacity: 0 }}
            animate={!isLoading ? "open" : "close"}
            transition={{ duration: 1 }}
          >
            <SmoothScroll>
              <div className="bg-white">
                <Home />
                <About />
                <Services />
                <Contact />
              </div>
            </SmoothScroll>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
}

export default App;
