export const service = [
    {
        image :'/service/1.jpg',
        heading : 'Research & Analysis',
        detail : 'Committed to thorough policy and issue research, the team delivers current policy and issue analyses that support practical legislative and policy solutions. The Research Team also provides insights that our client can utilise for effective communication with the electorate.',
        tag : [
            'Election Manifesto Analysis',
            'Political and Policy Research',
            'Client Communication Insights',
            'Chargesheets and White Papers', 
        ],
    },
    {
        image :'/service/2.jpg',
        heading : 'Political & Ground Intelligence',
        detail : 'Our Political Intelligence Team is committed to delivering exceptional insights and strategic guidance within the intricate landscape of Indian politics. Comprised of experienced analysts, researchers, and strategic advisors, our team excels in gathering, analysing, and interpreting political data to inform and empower our clients.',
        tag : [
            'Strategic Advisory',
            'Monitoring and Reporting',
            'Client Relations Management',
            'Risk Assessment and Issue Analysis',  
        ],
    },
    {
        image :'/service/3.jpg',
        heading : 'Campaign Management',
        detail : 'Our Campaign Management Team leads the entire campaign lifecycle, from ideation and monitoring to execution and reporting, ensuring a tailored approach to engage with diverse demographics within the region. The team focuses on enhancing client outreach through interactive events and campaign strategies to connect with the electorate.',
        tag : [
            'Stakeholder Engagement',
           'Voter Engagement Strategies',
           'Comprehensive Sub-Campaigns',
           'Event Planning and Management', 
        ],
    },
    {
        image :'/service/4.jpg',
        heading : 'Media & Communication',
        detail : 'The Communications Team gathers, processes, and distributes information about the client and its messaging across various channels – including print, digital, and social media. Through surprise campaigns, creative production, and media analysis and management, we ensure a compelling narrative that resonates with the audience.',
        tag : [
            'Monitoring and Analysis',
            'Strategic Communications',
            'Public Relations Campaigns', 
            'Narrative Building and Storytelling',
        ],
    },
    {
        image :'/service/5.jpg',
        heading : 'Analytics & Engineering',
        detail : 'Our Data Analytics team provides relevant data and insights that drive strategic decisions and campaign success, supporting informed decision-making across teams. Comprising experts in data science, statistics, and geospatial analysis, our team excels in gathering, analysing, and visualising data to inform and optimise every aspect of political strategy.',
        tag : [
            'Predictive Analytics',
            'Trend and Pattern Analysis',
            'Data Infrastructure Development', 
            'Voter Segmentation and Targeting',
        ],
    },
]

export const coorClient = [
    '/coor/ak-infra.jpeg',
    '/coor/bahu-nepali.jpeg',
    '/coor/freshever.jpeg',
    '/coor/hotever.jpeg',
    '/coor/js.jpeg',
    '/coor/themodify.jpeg',
]

export const poliClient = [
    '/poli/aam-aadmi-party.jpg',
    '/poli/bahujan-samaj-party.jpg',
    '/poli/bjp.png',
    '/poli/congress.png',
    '/poli/jdu.png',
    '/poli/samaj.webp',
]

export const phoneNumber = [
    '+91 9335 613 201',
    '+91 9450 924 610',
]

export const emailAddress = [
    'contact@digideck.in',
    'hrteam@digideck.in',
]

export const whatsappNumber = '919335613201'
export const whatsappLink = `https://wa.me/${whatsappNumber}`

export const socialLinks = {
    fb: 'https://www.facebook.com/digideckconsulting',
    instagram : 'https://www.instagram.com/digideckconsulting/',
    linkedin : 'https://www.linkedin.com/company/digideckconsulting/',
    x : 'https://x.com/digideckconsult',
    whatsapp : `https://wa.me/${whatsappNumber}`,
}