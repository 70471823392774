import React, { ComponentType } from "react";

function SectionWrapper(Component: any, idName: string) {
  function HOC() {
    return (
      <div className=" relative">
        <span className="hash-span absolute -top-[90px]" id={idName} />
        <Component />
      </div>
    );
  }
  return HOC;
}

export default SectionWrapper;
