"use client";
import gsap from "gsap";
import React, {
  FC,
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  useEffect,
} from "react";

interface MagneticProps {
  children: ReactElement<
    { ref: MutableRefObject<null> },
    string | JSXElementConstructor<any>
  >;
}

const Magnetic: FC<MagneticProps> = ({ children }) => {
  const magnetic: any = React.useRef(null);
  useEffect(() => {
    let hasMouse = window.matchMedia("(pointer: fine)").matches;
    if (!hasMouse) {
      return;
    }
    const xTo = gsap.quickTo(magnetic.current, "x", {
      duration: 1,
      ease: "elastic.out(1, 0.3)",
    });
    const yTo = gsap.quickTo(magnetic.current, "y", {
      duration: 1,
      ease: "elastic.out(1, 0.3)",
    });
    magnetic.current!.addEventListener(
      "mousemove",
      (e: { clientX: any; clientY: any }) => {
        const { clientX, clientY } = e;
        const { height, width, left, top } =
          magnetic.current.getBoundingClientRect();
        const x = clientX - (left + width / 2);
        const y = clientY - (top + height / 2);
        xTo(x * 0.3);
        yTo(y * 0.3);
      }
    );
    magnetic.current!.addEventListener("mouseleave", () => {
      xTo(0);
      yTo(0);
    });
  }, []);
  return React.cloneElement(children, { ref: magnetic });
};

export default Magnetic;
