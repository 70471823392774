"use client";
import React, { FC, useRef } from "react";
import { motion, useInView } from "framer-motion";

interface LeftRightProps {
  children: JSX.Element;
  width?: "fit-content" | "100%";
  custom?: number;
  left?: boolean;
}

const LeftRight: FC<LeftRightProps> = ({
  children,
  width,
  custom = 1,
  left = true,
}) => {
  const leftright = {
    initial: {
      x: left ? "-100%" : "100%",
      opacity: 0,
    },
    open: (i: number) => ({
      x: "0%",
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.2 * i,
        ease: [0.7, 0, 0.3, 1],
      },
    }),
    closed: {
      x: left ? "-100%" : "100%",
      opacity: 0,
      transition: { duration: 0.5 },
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "0px 100px -20px 0px",
    once: true,
  });

  return (
    <div ref={ref} style={{ width }} className="">
      <motion.div
        variants={leftright}
        initial="initial"
        animate={isInView ? "open" : "closed"}
        custom={custom}
        className=" relative h-fit"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default LeftRight;
