"use client";
import { useState } from "react";
import { IoIosMenu, IoLogoWhatsapp } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import Magnetic from "./ui/Magnetic/Magnetic";
import { whatsappLink } from "../constants";
import { motion } from "framer-motion";

export default function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const scrolling = (element: any) => {
    document.getElementById(element)!.scrollIntoView({
      behavior: "smooth",
    });
    setIsOpen(false);
  };
  const leftright = {
    initial: {
      x: "-100%",
      // opacity: 0,
    },
    open: (i: number) => ({
      x: "0%",
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: [0.7, 0, 0.3, 1],
      },
    }),
    closed: {
      x: "-100%",
      // opacity: 0,
      ease: [0.7, 0, 0.3, 1],
      transition: { duration: 0.4 },
    },
  };
  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-[9999] bg-white font-digideck2">
        <div className="flex relative justify-between items-center mt-2 px-[3vw]">
          <div className="flex justify-center items-center">
            <button onClick={() => scrolling("home")}>
              <img
                src={"/favicon.ico"}
                width={90}
                height={90}
                alt="digideck-logo"
              />
            </button>
            <span className="text-[#1C2D6B] lg:text-3xl md:text-lg sm:text-xl text-lg font-semibold typing-demo">
              DigiDeck Consulting
            </span>
          </div>
          <div className="md:flex hidden bg-gray-90 p-[2px] rounded-3xl text-black text-lg font-semibold">
            <Magnetic>
              <button
                onClick={() => scrolling("home")}
                className={`  text-[#1C2D6B] hover:text-[#FFA301] ease-in px-6 py-3 rounded-full cursor-pointer`}
              >
                Home
              </button>
            </Magnetic>
            <Magnetic>
              <button
                onClick={() => scrolling("about")}
                className={`px-6 py-3 rounded-full text-[#1C2D6B] hover:text-[#FFA301] ease-in cursor-pointer`}
              >
                About us
              </button>
            </Magnetic>
            <Magnetic>
              <button
                onClick={() => scrolling("services")}
                className={`px-6 py-3 rounded-full text-[#1C2D6B] hover:text-[#FFA301] ease-in cursor-pointer `}
              >
                Services
              </button>
            </Magnetic>
            <Magnetic>
              <button
                onClick={() => scrolling("contact")}
                className={`px-6 py-3 rounded-full text-[#1C2D6B] hover:text-[#FFA301] ease-in cursor-pointer `}
              >
                Contact
              </button>
            </Magnetic>
            <Magnetic>
              <a
                target="_blank"
                href={whatsappLink}
                className="flex justify-center items-center"
              >
                <IoLogoWhatsapp size={25} color="green" />
              </a>
            </Magnetic>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden block"
          >
            {isOpen ? null : <IoIosMenu color="#1C2D6B" size={40} />}
          </button>
        </div>
      </div>
      <div
        className={`fixed md:hidden z-[9999] inset-0 bg-[#1C2D6B]/50  flex flex-col items-start text-xl font-bold shadow-2xl  ${
          !isOpen
            ? "bg-transparent overflow-auto pointer-events-none"
            : "bg-[#1C2D6B]/50 overflow-hidden pointer-events-auto"
        }`}
        onClick={() => setIsOpen(false)}
      >
        <div className=" w-full h-full flex flex-row">
          <motion.div
            variants={leftright}
            initial="initial"
            animate={isOpen ? "open" : "closed"}
            custom={0}
            className={`bg-[#1C2D6B]/90 z-[100000] w-fit text-nowrap pr-20 pt-[100px] flex flex-col h-full`}
          >
            <button
              onClick={() => {
                scrolling("home");
                setIsOpen(false);
              }}
              className={`   text-[#fff] px-6 py-3 pointer-events-auto`}
            >
              Home
            </button>
            <button
              onClick={() => {
                scrolling("about");
                setIsOpen(false);
              }}
              className={`px-6 py-3 pointer-events-auto text-[#fff]`}
            >
              About us
            </button>
            <button
              onClick={() => {
                scrolling("services");
                setIsOpen(false);
              }}
              className={`px-6 py-3 pointer-events-auto text-[#fff] `}
            >
              Services
            </button>
            <button
              onClick={() => {
                scrolling("contact");
                setIsOpen(false);
              }}
              className={`px-6 py-3 pointer-events-auto text-[#fff] `}
            >
              Contact
            </button>
          </motion.div>
          <div
            onClick={() => setIsOpen(false)}
            className={`h-[90px] flex justify-end items-center w-full px-[3vw] mt-2 ${
              !isOpen
                ? "hidden pointer-events-none"
                : "block pointer-events-auto"
            }`}
          >
            <RxCross1 color="#fff" size={40} />
          </div>
        </div>
      </div>
      <a
        href={whatsappLink}
        className="sm:hidden flex fixed left-5 bottom-14 z-[99] bg-white p-1 rounded-full"
      >
        <IoLogoWhatsapp size={30} color="green" />
      </a>
    </>
  );
}
