"use client";
import ReactLenis from "@studio-freight/react-lenis";
import React, { FC, useEffect, useState } from "react";
import Header from "./Header";
import Cursor from "./cursor/Cursor";
import CursorClass from "../utils/cursor";

interface SmoothScrollProps {
  children: React.ReactNode;
}

const SmoothScroll: FC<SmoothScrollProps> = ({ children }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [volume, setVolume] = useState<boolean>(true);
  useEffect(() => {
    // let cursor: CursorClass | null = new CursorClass(
    //   document.querySelector(".cursor")!
    // );
    // let cursorTxt = document.querySelector(".cursor-txt")! as HTMLSpanElement;
    const mouseClickEffect = (e: MouseEvent) => {
      let clickSound: HTMLAudioElement | null = new Audio("/hoversound.mp3");
      clickSound.volume = volume ? 1 : 0;
      if (!clicked) setClicked(true);
      // cursorTxt.style.opacity = "0";
      clickSound.play();
      clickSound.addEventListener("ended", () => {
        clickSound = null;
      });
    };
    const mouseEnterHandler = () => {
      // cursor!.enter();
      // if (!clicked) cursorTxt.style.opacity = "0";
      if (clicked) {
        let hoverSound: HTMLAudioElement | null = new Audio("/clicksound.wav");
        hoverSound.volume = volume ? 0.3 : 0;
        hoverSound.play();
        hoverSound.addEventListener("ended", () => {
          hoverSound = null;
        });
      }
    };
    // const mouseLeaveHandler = () => {
    //   // cursorTxt.style.opacity = clicked ? "0" : "1";
    //   // cursor!.leave();
    // };
    const mouseHide = () => {
      // cursor!.hide();
      if (clicked) {
        let hoverSound: HTMLAudioElement | null = new Audio("/clicksound.wav");
        hoverSound.volume = volume ? 0.3 : 0;
        hoverSound.play();
        hoverSound.addEventListener("ended", () => {
          hoverSound = null;
        });
      }
    };
    const triggerEle = document.querySelectorAll("a , button, .a");
    triggerEle.forEach((link) => {
      link.addEventListener("mouseenter", mouseEnterHandler);
      // link.addEventListener("mouseleave", mouseLeaveHandler);
    });
    const hide = document.querySelectorAll(".hideCursor");
    hide.forEach((link) => {
      link.addEventListener("mouseenter", mouseHide);
      // link.addEventListener("mouseleave", () => cursor!.visible());
    });
    document.addEventListener("click", mouseClickEffect);
    return () => {
      triggerEle.forEach((link) => {
        link.removeEventListener("mouseenter", mouseEnterHandler);
        // link.removeEventListener("mouseleave", mouseLeaveHandler);
      });
      hide.forEach((link) => {
        link.removeEventListener("mouseenter", mouseHide);
        // link.removeEventListener("mouseleave", () => cursor!.visible());
      });
      document.removeEventListener("click", mouseClickEffect);
    };
  }, [clicked, volume]);
  return (
    <div className="bg-white">
      <ReactLenis
        root
        options={{
          lerp: 0.1,
          duration: 1.5,
          smoothWheel: true,
        }}
      >
        <Header />
        {/* <Cursor /> */}
        {children}
      </ReactLenis>
    </div>
  );
};

export default SmoothScroll;
