import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SectionWrapper from "./SectionWrapper";
import LeftRight from "./LeftRight";
import Fade from "./Fade";
import { service } from "../constants";

function Services() {
  return (
    <div className=" h-fit mb-36 overflow-hidden">
      <div className=" font-digideck2">
        <Fade>
          <div className="py-16 px-[8vw] flex justify-center items-center flex-col bg-[#1C2D6B] z-[10] text-white">
            <h1 className="text-4xl text-center text-[#FFA301] font-bold">
              Services
            </h1>
            <p className="md:px-10 px-4 mt-6  text-base text-center">
              We are dedicated to delivering outstanding solutions, meticulously
              designed to offer comprehensive services that support our clients
              in achieving their political objectives and establishing a strong
              presence within India’s political landscape.
            </p>
          </div>
        </Fade>
      </div>
      <div className="w-fit h-fit mt-20 lg:px-[12vw] md:px-10 px-4">
        {service.map((item, index) => {
          return (
            <LeftRight key={index} left={index % 2 === 0}>
              <div
                key={index}
                className={`flex md:flex-row h-fit flex-col md:px-8 px-4 gap-x-8 gap-y-3 py-10 mb-10 shadowCustom bg-white rounded-3xl font-bold ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                }`}
              >
                <img
                  src={item.image}
                  height={200}
                  width={200}
                  alt="services"
                  className="md:w-[33%] w-full object-contain"
                />
                {/* <Image
                  src={item.image}
                  height={200}
                  width={200}
                  unoptimized
                  alt="services"
                  className=" md:w-[33%] w-full object-contain"
                /> */}
                <div className="md:w-[33%] w-full flex justify-center items-center flex-col">
                  <div className=" h-fit w-full">
                    <div className="text-3xl text-[#FFA301] text-center font-medium">
                      {item.heading}
                    </div>
                    <div className="md:mt-8 mt-4 text-sm text-black text-justify font-digideck2">
                      {item.detail}
                    </div>
                  </div>
                </div>
                <div className="md:w-[33%] w-full items-center justify-center flex flex-col">
                  <div className="h-fit">
                    {item.tag.map((ele, i) => {
                      return (
                        <div
                          key={i}
                          className="px-4 py-2 mt-4 flex w-full items-center gap-x-2 rounded-xl bg-[#1C2D6B] text-white"
                        >
                          <div className="">
                            <IoMdCheckmarkCircleOutline
                              color="#fff"
                              size={20}
                            />
                          </div>
                          <span>{ele}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div className={`flex h-fit justify-around items-center mt-8`}>
              </div> */}
              </div>
            </LeftRight>
          );
        })}
      </div>
    </div>
  );
}

export default SectionWrapper(Services, "services");
