"use client";
import { FC, useRef } from "react";
import { motion, useInView } from "framer-motion";

interface FadeProps {
  children: JSX.Element;
  width?: "fit-content" | "100%";
  custom?: number;
}

const Fade: FC<FadeProps> = ({ children, width, custom = 1 }) => {
  const Fade = {
    initial: {
      //   y: "100%",
      opacity: 0,
      scaleX: 0.5,
    },
    open: (i: number) => ({
      //   y: "0%",
      opacity: 1,
      scaleX: 1,

      transition: {
        duration: 0.5,
        delay: 0.2 * i,
        ease: [0.7, 0, 0.3, 1],
      },
    }),
    closed: {
      //   y: "100%",
      opacity: 0,
      scaleX: 0.5,
      transition: { duration: 0.5 },
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "0px 100px -20px 0px",
    once: true,
  });

  return (
    <div ref={ref} style={{ width, overflow: "hidden" }} className="">
      <motion.div
        variants={Fade}
        initial="initial"
        animate={isInView ? "open" : "closed"}
        custom={custom}
        className=" relative h-fit"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Fade;
