"use client";
import { RiArrowUpSLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import SectionWrapper from "./SectionWrapper";
import { scrolling } from "../utils/utils";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { AnimatePresence, motion } from "framer-motion";

const Home = () => {
  const [isVideoReady, setIsVideoReady] = useState(true);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".homecontainer",
        start: "bottom 50%",
        scrub: 1,
      },
    });
    tl.to(".gototop", {
      opacity: 1,
    });
  }, []);
  const handleCanPlayThrough = () => {
    setTimeout(() => {
      setIsVideoReady(false);
    }, 1000);
  };
  const menuSlide = {
    initial: { opacity: 1 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };
  const videoFrame = {
    initial: {
      opacity: 0,
      clipPath: " polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)",
    },
    enter: {
      opacity: 1,
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
    },
    exit: {
      opacity: 0,
      clipPath: " polygon(28% 30%, 73% 30%, 74% 72%, 29% 73%)",
    },
  };
  return (
    <>
      <div className="font-digideck2 homecontainer mt-[90px] relative sm:mb-0 mb-20 flex justify-center items-center flex-col text-gray-900">
        <img
          src="/home.png"
          alt="home"
          className="w-full h-full object-cover"
        />
        {/* <motion.video
          variants={videoFrame}
          initial="initial"
          animate={!isVideoReady ? "enter" : "exit"}
          transition={{ duration: 0.3 }}
          autoPlay
          onPlay={handleCanPlayThrough}
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source src={"/video.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </motion.video>
        <AnimatePresence>
          {isVideoReady && (
            <motion.div
              variants={menuSlide}
              initial="initial"
              animate="enter"
              exit="exit"
              className="z-10 text-black flex justify-center items-center flex-col bg-white h-[calc(100vh+90px)] -mt-[90px] w-full"
            >
              <h1 className="text-[7.7vw] font-thin text-center font-digideck2 leading-[1] ">
                <span>
                  Strategy That{" "}
                  <span className="text-[#FFA301] font-bold">Speaks</span>
                </span>
              </h1>
              <h1 className="text-[7.7vw] font-thin justify-center font-digideck2 leading-[1] flex">
                <span className=" flex items-center">
                  <span className="text-[#155B08] font-bold">Results</span> That
                  Last
                </span>
              </h1>
            </motion.div>
          )}
        </AnimatePresence> */}
      </div>
      <div>
        <div
          onClick={() => scrolling("home")}
          className="flex gototop opacity-0 flex-col p-1 bg-[#1C2D6B] border border-white/20 cursor-pointer rounded-full justify-center z-[9999] items-center fixed right-5 bottom-14 a"
        >
          <RiArrowUpSLine size={30} color="#FFA301" className="" />
        </div>
      </div>
    </>
  );
};

export default SectionWrapper(Home, "home");
