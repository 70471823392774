"use client";
import React, { useState } from "react";
import SectionWrapper from "./SectionWrapper";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { BsPhoneFill } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Magnetic from "./ui/Magnetic/Magnetic";
import { emailAddress, phoneNumber, socialLinks } from "../constants";
import { LiaSpinnerSolid } from "react-icons/lia";
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const [status, setStatus] = useState("Send Message");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://digideck.in/sendEmail.php",
        formData,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
    } catch (error) {
      // setStatus("Error!");
      // console.log("something went wrong ", error);
    } finally {
      setStatus("Sent!");
      setLoading(false);
      setTimeout(() => {
        setStatus("Send Message");
        setFormData({
          name: "",
          email: "",
          message: "",
          phone: "",
        });
      }, 1000);
      console.log("Form submitted:", formData);
    }
  };
  return (
    <footer className="pt-20 bg-gray-200">
      <div className="px-[4vw] pb-10 text-black font-digideck2">
        <div className="flex md:flex-row flex-col gap-y-8">
          <div className="flex flex-[1.5] sm:flex-row flex-col gap-y-8">
            <div className="flex-[2] flex flex-col">
              <h1 className="text-4xl text-[#1C2D6B] font-bold">
                DigiDeck Consulting
              </h1>
              <p className="mt-4">
                DigiDeck Consulting specialises in political communication,
                constituency and candidate profiling and strategy, campaign
                management, digital and social media strategy, and polling.
              </p>
              <div className="mt-6">
                <div className="flex gap-x-2 justify-center items-center w-fit">
                  <MdLocationPin color="#1C2D6B" size={20} />
                  <span className="text-lg font-bold">
                    Corporate Office: New Delhi
                  </span>
                </div>
                <p className="px-3 flex flex-col text-sm">
                  <span>G-18/42, Rohini Sector-15,</span>
                  <span>New Delhi - 110089</span>
                </p>
              </div>
              <div className="mt-6">
                <div className="flex gap-x-2 justify-center items-center w-fit">
                  <MdLocationPin color="#1C2D6B" size={20} />
                  <span className="text-lg font-bold">
                    Head Office: Lucknow
                  </span>
                </div>
                <p className="px-3 flex flex-col text-sm">
                  <span>619/16M, Vednathpuram, NH24 Highway, </span>
                  <span>Lucknow - 226021</span>
                </p>
              </div>
            </div>
            <div className="flex-1 flex flex-col sm:pl-10 pl-0">
              <h1 className="text-xl font-bold text-[#1C2D6B]">Contact Us</h1>
              <div>
                <div className="mt-6">
                  <div className="flex gap-x-2 justify-center items-center w-fit">
                    <BsPhoneFill color="#1C2D6B" size={20} />
                    Phone
                  </div>
                  <p className=" mt-2 px-3 flex flex-col text-sm">
                    {phoneNumber.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </p>
                </div>
                <div>
                  <div className="flex gap-x-2 justify-center items-center w-fit mt-9">
                    <MdEmail color="#1C2D6B" size={20} />
                    Email
                  </div>
                  <p className=" px-3 flex flex-col text-sm mt-2">
                    {emailAddress.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-x-6 mt-10">
                <Magnetic>
                  <a target="_blank" href={socialLinks.fb}>
                    <FaFacebook size={25} color="blue" />
                  </a>
                </Magnetic>
                <Magnetic>
                  <a target="_blank" href={socialLinks.instagram}>
                    <FaInstagram size={25} color="#d62976" />
                  </a>
                </Magnetic>
                <Magnetic>
                  <a target="_blank" href={socialLinks.linkedin}>
                    <FaLinkedinIn size={25} color="#0077B5" />
                  </a>
                </Magnetic>
                <Magnetic>
                  <a target="_blank" href={socialLinks.x}>
                    <FaSquareXTwitter size={25} color="black" />
                  </a>
                </Magnetic>
              </div>
            </div>
          </div>
          <div className="flex flex-[1] flex-col md:pl-10 pl-0">
            <h1 className="text-xl text-[#1C2D6B] font-bold">
              Send us a message
            </h1>
            <form
              action="send-email.php"
              onSubmit={handleSubmit}
              className=" rounded pt-6 pb-8 mb-4"
            >
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Name"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Phone Number"
                />
              </div>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Email"
                />
              </div>
              <div className="mb-4">
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="bg-blue-500 flex justify-center items-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  {loading ? (
                    <span className="flex justify-center items-center animate-spin">
                      <LiaSpinnerSolid size={10} color="#fff" />
                    </span>
                  ) : (
                    <span>{status}</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-[#1C2D6B] text-white sm:py-10 pt-14 pb-10 text-center">
        ©{currentYear}{" "}
        <span className="font-bold sm:text-base text-sm  text-[#FFA301]">
          DigiDeck Consulting
        </span>
        . All Rights Reserved{" "}
      </div>
    </footer>
  );
}

export default SectionWrapper(Contact, "contact");
