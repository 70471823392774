import Typewriter from "typewriter-effect";
import { PiSpinnerGap, PiSpinnerLight } from "react-icons/pi";
const qutoes = [
  "<span style='font-size:7vw;font-weight:500'>Transforming <span style='font-weight:600;color:orange'>Visions</span><br/>into <span style='color:green; font-weight:600'>Victory</span></span>",
  "<span style='font-size:7vw;font-weight:500'><span style='font-weight:600'>Strategy</span> That <span style='color:orange'>Speaks,</span><br/><span style='color:green; font-weight:600'>Results</span> That Last</span>",
  "<span style='font-size:7vw;font-weight:500'>Focused <span style='font-weight:600;color:orange'>Strategy,</span><br/>Sustainable <span style='color:green; font-weight:600'>Success</span></span>",
  "From Ideas to Impact,<br/>Built to Last",
  "Empowering Change,<br/>Delivering Results",
];
function Preloader() {
  return (
    <div className="h-screen w-full bg-white justify-center items-center flex flex-col">
      {/* <div className="text-[#1C2D6B] text-[4.7vw]"> */}
      <div className="text-[#1C2D6B] text-[6.7vw] font-semibold mb-10">
        DigiDeck Consulting
      </div>
      <div className="bg-white rounded-full flex justify-center items-center flex-col relative">
        {/* <Typewriter
          options={{
            loop: true,
            autoStart: true,
            strings: [qutoes[0], qutoes[1], qutoes[2]],
          }}
          onInit={(typewriter) => {
            typewriter.pauseFor(500).deleteAll().pauseFor(1000).start();
          }}
        /> */}
        <img
          src={"/favicon.ico"}
          width={90}
          height={90}
          alt="digideck-logo"
          className=""
        />
        <span className=" rounded-full">
          <PiSpinnerLight color="#1C2D6B" size={20} className="animate-spin" />
        </span>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Preloader;
